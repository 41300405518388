.channelContainer {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  .channel {
    cursor: pointer;
    margin: 3px;
    height: 40px;
    border-radius: 4px;
    .channel__image {
      height: 40px;
      max-width: 100px;
      // border-radius: 4px;
      object-fit: scale-down;
    }
    .channel__text {
      font-family: Montserrat-Medium;
      font-size: 13px;
      text-align: center;
      padding: 0 5px;
      color: #0e2c0e;
    }
  }
  .channel__more {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
    p {
      font-family: Montserrat-Bold;
      color: gray;
      margin: 0;
    }
  }
  .channel__selected {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 5;
  }
  .channel__promotion {
    position: absolute;
    top: -6px;
    left: 5px;
  }
  .channel--grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

.channel--border {
  border: solid 1px var(--pagsmile--color) !important;
  background-color: #ffffff;
  border-radius: 4px;
}

@media only screen and (max-width: 800px) {
  .channelContainer {
    justify-content: center;
  }
  .channelPromotion {
    display: flex;
    flex-direction: column;
    align-items: center;
    .channelPromotion__notice {
      text-align: center;
    }
  }
}
