.checkoutKlapCash {
  margin-right: 50px;
  .checkoutKlapCash__mobileCopy {
    display: none;
  }
  .checkoutKlapCash__inst {
    background-color: #eefaea;
    padding: 5px 20px;

    .checkoutKlapCash__instTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 0.6rem 0;
    }
    .checkoutKlapCash__instCont {
      font-family: Montserrat-Regular;
      font-size: 14px;
      margin: 0.6rem 0;
      a {
        color: #3a87ef;
        text-decoration: none;
      }
      .checkoutKlapCash__instCont--bold {
        font-family: Montserrat-Bold;
        color: var(--pagsmile--color);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutKlapCash {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 50px;
    text-align: center;
    .checkoutKlapCash__inst {
      .checkoutKlapCash__instCont {
        text-align: start;
        padding-left: 20px;
        li {
          margin-bottom: 5px;
          line-height: 1.5;
        }
      }
    }
    .checkoutKlapCash__mobileCopy {
      display: block;
      padding: 5px 20px;
    }
  }
}
