.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .status__payButton {
    height: 50px;
    width: 100%;
    margin-top: 0px;
    color: #ffffff;
    font-family: Montserrat-Regular;
    font-size: 20px;
    border: none;
    background-color: var(--pagsmile--color);
    border-radius: 4px;
    cursor: pointer;
  }
  .status__payButton:active {
    background-color: #048367;
    transition: background-color 0.2s ease-in;
  }

  .status__returnButtton {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #1e86ff;
  }
  .status__returnButtton:hover {
    color: #1966be;
    transition: background-color 0.2s ease-in;
  }
  .status__time {
    display: flex;
    width: 100%;
    margin-left: 30px;
    box-sizing: border-box;
  }
  .status__payDetail__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    .status__payDetail__totalText {
      font-family: Montserrat-Medium;
      font-size: 15px;
      color: #0e2c0e;
    }
    .status__payDetail__totalValue {
      font-family: Montserrat-Regular;
      font-size: 20px;
      margin-left: 6px;
      color: #0e2c0e;
    }
  }
}

hr {
  border-width: 0;
  height: 1px;
  background-color: rgba(145, 145, 147, 0.2);
  width: 100%;
}
