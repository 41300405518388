.checkoutTransfiya {
  margin-right: 50px;
  .checkoutTransfiya__inst--bold {
    color: var(--pagsmile--color);
    font-family: Montserrat-Bold;
  }
  .checkoutTransfiya__inst {
    font-family: Montserrat-Medium;
    font-size: 14px;
    background-color: #eefaea;
    padding: 20px;
    .checkoutTransfiya__logo {
      margin-left: 25px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutTransfiya {
    background-color: #fff;
    margin-right: 0;
    .checkoutTransfiya__inst {
      .checkoutTransfiya__logo {
        margin-left: 0;
        display: flex;
        justify-content: center;
      }
      ol {
        padding-left: 20px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}
