.checkoutKiosk {
  margin-right: 50px;
  font-family: Montserrat-Regular;
  table,
  th {
    border: 1px solid #d2d5d8;
  }
  table {
    font-family: Montserrat-Regular;
    color: #333435;
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    th .row {
      display: flex;
      justify-content: space-between;
      padding: 3px 8px;
      font-size: 14px;
    }
  }
  .title {
    font-family: Montserrat-Bold;
    font-size: 15px;
  }
  .payment-points {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #d2d5d8;
    padding: 20px 5px;
    border-radius: 6px;
    .point {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 13px;
      }
    }
  }
  .how-to {
    border: 1px solid #d2d5d8;
    padding: 10px 5px;
    border-radius: 6px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .checkoutKiosk {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 50px;
    text-align: center;
    .how-to {
      text-align: start;
    }
  }
}
