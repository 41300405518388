.localpaymentBol {
  margin-right: 50px;
  font-family: Montserrat-Medium;
  .localpaymentBol__qr {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #e3e3e3 1px solid;
    padding: 20px;
    margin-bottom: 20px;
    img {
      width: 200px;
    }
  }
  .localpaymentBol__how {
    border: #e3e3e3 1px solid;
    border-radius: 6px;
    h3 {
      text-align: center;
      font-size: 16px;
    }
    ol {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .localpaymentBol {
    background-color: #fff;
    margin-right: 0;
  }
}
