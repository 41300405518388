.resultMobile {
  display: none;
}
.result {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .result__right {
    flex-grow: 1;
    .result__payResult {
      padding-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .result__payResult__title {
        font-family: Montserrat-Bold;
        font-size: 16px;
        color: #000104;
      }
      .result__payResult__resultContainer {
        position: relative;
        .result__payResult__noticeContainer {
          position: absolute;
          top: 30px;
          right: calc(50% - 100px);
          .result__payResult__notice {
            display: none;
            position: absolute;
            top: 0px;
            left: 40px;
            height: 130px;
            width: 200px;
            background-color: #ffffff;
            border: 1px solid #dee8f2;
            box-shadow: 0px 9px 35px 0px #f4f4f4;
            border-radius: 4px;
            padding: 20px;
            span {
              margin-left: 10px;
              font-family: Montserrat-Regular;
              font-size: 16px;
              color: var(--pagsmile--color);
            }
            p {
              font-family: Montserrat-Regular;
              font-size: 12px;
              color: #b3b2b2;
            }
          }
        }
        .result__payResult__noticeContainer:hover .result__payResult__notice {
          display: block;
        }
      }
      .result__payResult__buttons {
        .result__payResult__buttonYes {
          font-size: 16px;
          font-family: Montserrat-Regular;
          color: #ffffff;
          width: 150px;
          height: 40px;
          background-color: var(--pagsmile--color);
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        .result__payResult__buttonNo {
          margin-left: 20px;
          background-color: rgba(54, 174, 55, 0);
          border: 1px solid var(--pagsmile--color);
          border-radius: 4px;
          font-size: 16px;
          font-family: Montserrat-Regular;
          color: var(--pagsmile--color);
          width: 150px;
          height: 40px;
          cursor: pointer;
        }
      }
      .result__payResult__success {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          font-size: 14px;
          font-family: Montserrat-Regular;
          color: #ffffff;
          min-width: 160px;
          height: 40px;
          background-color: #04b88f;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

.result--success--redirect {
  text-align: center;
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #000104;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(145, 145, 147, 0.2);
  padding: 10px;
  p {
    span:nth-child(2) {
      color: var(--pagsmile--color);
      cursor: pointer;
    }
  }
}

.payResult__refresh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .payResult__refreshNotice {
    font-family: Montserrat-Regular;
    font-size: 12px;
    color: #878787;
    text-align: center;
  }
  .payResult__refreshButton {
    position: relative;
    text-align: center;
    margin: 10px;
    button {
      border: 1px solid var(--pagsmile--color);
      background-color: #fff;
      text-transform: none;
      font-family: Montserrat-Regular;
      color: var(--pagsmile--color);
      font-size: 15px;
      transition: color 1s;
      transition: background-color 1s;
      min-width: 120px;
      height: 40px;
    }
    .payResult__refresh__loading {
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 10px);
      color: var(--pagsmile--color);
    }
  }
}

@media only screen and (max-width: 800px) {
  .result {
    display: none;
  }
  .resultMobile {
    display: block;
    padding: 110px 10px 80px;
    background-color: #f1f4f8;
    min-height: 100%;
    box-sizing: border-box;
    .resultMobile__payDetails {
      background-color: #ffffff;
      padding: 5px 20px 20px;
      border-radius: 4px;
      hr {
        border-width: 0;
        height: 1px;
        background-color: rgba(145, 145, 147, 0.2);
        width: 100%;
      }
      .resultMobile__payDetails__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .resultMobile__payDetails__text {
          font-family: Montserrat-Regular;
          font-size: 15px;
          color: #0c3666;
          margin: 5px 0;
        }
        .resultMobile__payDetails__value {
          font-family: Montserrat-Bold;
          font-size: 18px;
          color: #0c3666;
          margin: 5px 0;
        }
        img {
          max-height: 35px;
          max-width: 100px;
          object-fit: scale-down;
        }
      }
      .resultMobile__payDetails__time {
        margin: 20px 0;
        display: flex;
      }
    }

    .resultMobile__footer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
      z-index: 100;
      .resultMobile__footer__returnButton {
        background-color: var(--pagsmile--color);
        border: none;
        border-radius: 4px;
        height: 50px;
        margin: 10px 0;
        color: #ffffff;
        font-family: Montserrat-Regular;
        font-size: 18px;
      }
    }

    .resultMobile__return a:hover {
      color: #1966be;
      transition: background-color 0.2s ease-in;
    }
    .resultMobile__payResult {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-height: 500px) {
  .resultMobile {
    padding-top: 75px;
  }
}
