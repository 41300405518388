.checkoutMobile {
  display: none;
}
.checkout {
  display: flex;
  justify-content: center;
  .checkout__container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 30px;
    margin-bottom: 50px;
    box-shadow: 0 8px 35px -25px rgba(0, 0, 0, 0.35);
    .checkout__left {
      box-sizing: border-box;
      line-height: 1.8;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

hr {
  border-width: 0;
  height: 1px;
  background-color: rgba(145, 145, 147, 0.2);
  width: 100%;
  margin: 5px 0;
}
.checkout--floatRight {
  display: flex;
  justify-content: flex-end;
}
.checkout--text {
  font-family: Montserrat-Regular;
  font-size: 13px;
  color: #878787;
}
.checkout--value {
  font-family: Montserrat-Regular;
  font-size: 13px;
  color: #0e2c0e;
}
.checkout--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout--hide {
  font-family: DMSans-Medium;
  font-size: 14px;
  color: #1e86ff;
  cursor: pointer;
}

.checkout--column {
  display: flex;
  flex-direction: column;
}

.checkout--row {
  display: flex;
}

.checkout--grow {
  flex-grow: 1;
}

@media only screen and (max-width: 800px) {
  .checkout {
    display: none;
  }
  .checkoutMobile {
    display: block;
    padding: 110px 10px 300px;
    background-color: #f2f3f7;
    box-sizing: border-box;
    min-height: 100vh;

    .checkoutMobile__top {
      background-color: #ffffff;
      padding: 5px 20px 20px;
      border-radius: 4px;
      .checkout--channelContainer {
        margin-bottom: 5px;
      }
      p {
        margin: 5px 0;
      }
      .checkoutMobile__top__totalText {
        font-family: Montserrat-Regular;
        font-size: 15px;
        color: #0c3666;
      }
      .checkoutMobile__top__totalValue {
        font-family: Montserrat-Regular;
        color: #000000;
        font-size: 20px;
        margin-left: 5px;
      }
    }

    .checkoutMobile__footer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
      z-index: 100;
      .checkoutMobile__footer__time {
        display: flex;
      }
      .checkoutMobile__footer__sortImg {
        width: 24px;
        margin: 0 auto;
        padding: 0 30px;
      }
      .checkoutMobile__footer__paymentTicket {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-family: DMSans-Regular;
          font-size: 16px;
          margin: 0;
        }
        img {
          height: 43px;
        }
      }
      .checkoutMobile__footer__returnButton {
        background-color: var(--pagsmile--color);
        border: none;
        border-radius: 4px;
        height: 50px;
        margin: 10px 0;
        color: #ffffff;
        font-family: Montserrat-Regular;
        font-size: 18px;
      }
      .checkoutMobile__footer__payButton {
        background-color: var(--pagsmile--color);
        border: none;
        border-radius: 4px;
        height: 50px;
        margin: 0 0 10px 0;
        color: #ffffff;
        .checkoutMobile__footer__payButtonText {
          font-family: Montserrat-Regular;
          font-size: 18px;
        }
        .checkoutMobile__footer__payButtonValue {
          font-family: Montserrat-Bold;
          font-size: 24px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .checkoutMobile {
    .checkoutMobile__footer {
      padding: 10px 20px 0 20px;
    }
  }
}

@media only screen and (max-height: 500px) {
  .checkoutMobile {
    padding-top: 80px;
  }
}
