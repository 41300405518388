.countryDropDown {
  .countryDropDown__countryFlag {
    width: 34px;
    height: 22px;
    object-fit: contain;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  }
}
.languageDropdown {
  .languageDropdown__langContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .languageDropdown__langText {
      margin-left: 5px;
      text-transform: none;
      font-family: Montserrat-Medium;
    }
  }
}
.countryDropDown,
.languageDropdown {
  .arrow__0 {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    filter: grayscale(100%);
  }
  .arrow__180 {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
    filter: grayscale(100%);
  }
}

.headerMobile {
  display: none;
}
.header {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: center;
  box-shadow: 0 8px 35px -25px rgba(0, 0, 0, 0.35);

  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 1200px;
    .header__pagsmile {
      max-width: 100%;
      width: 170px;
    }

    .header__rightContainer {
      display: flex;
      align-items: center;
    }
    .header__info {
      margin: 0 45px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 800px) {
  .countryDropDown {
    .MuiButton-text,
    .MuiButton-root {
      padding-left: 0;
      min-width: 75px;
    }
    .MuiButton-label {
      justify-content: flex-start;
    }
  }
  .header {
    display: none;
  }
  .headerMobile {
    position: fixed;
    z-index: 500;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 12.5px 15px;
    background-color: var(--pagsmile--color);
    .headerMobile__top {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .headerMobile__returnButton {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 5px;
      }
      .headerMobile__pagsmile {
        height: 35px;
        width: 130px;
      }
    }
    .headerMobile__bottom {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .headerMobile__bottom__left {
        display: flex;
        justify-content: center;
        align-items: center;
        .headerMobile__bottom__return {
          display: none;
          margin-right: 10px;
        }
      }
      .headerMobile__infoContainer {
        display: flex;
        justify-content: flex-end;
        min-width: 75px;
        .headerMobile__info {
          cursor: pointer;
        }
      }
    }
  }
  .header--langContainer {
    .header__langTextSelect {
      color: #ffffff;
      margin-right: 5px;
    }
  }
  .header--langContainer:hover .header__langDropDown {
    left: 0;
    right: auto;
  }
}

@media only screen and (max-height: 500px) {
  .headerMobile {
    .headerMobile__top {
      display: none;
    }
    .headerMobile__bottom {
      .headerMobile__bottom__left {
        .headerMobile__bottom__return {
          display: inline;
        }
      }
    }
  }
}
