.checkoutTitle {
  font-family: Montserrat-Bold;
  font-size: 15px;
  color: #0e2c0e;
  .checkoutTitle__amount {
    color: var(--pagsmile--color);
  }
}

@media only screen and (max-width: 800px) {
  .checkoutTitle {
    padding: 20px;
    padding-bottom: 0;
    text-align: center;
  }
}
