.app__loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.2);
}
.loader {
  position: relative;
  top: 40%;
  left: calc(50% - 30px);
}

@media only screen and (max-width: 800px) {
  .Toastify__toast-container--top-center {
    top: 87px !important;
  }
}

:root {
  --toastify-icon-color-success: #01a27e !important;
  --toastify-color-progress-success: #01a27e !important;
  --pagsmile--color: #01a27e;
}
