.bank131-Button__container {
  background-color: var(--pagsmile--color) !important;
}
.bank131-Button__container:focus {
  -webkit-box-shadow: inset 0 0 0 2px #067960 !important;
  box-shadow: inset 0 0 0 2px #067960 !important;
}
.bank131-Button__container:hover {
  background-color: #067960 !important;
}
.bank131-Button__container:active {
  background-color: #067960 !important;
}
