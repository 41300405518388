.channelSelect {
  width: 100%;
  .channelSelect__title {
    display: flex;
    justify-content: center;
    font-family: Montserrat-Bold;
    font-size: 13px;
    color: #0e2c0e;
    margin-bottom: 20px;
    span {
      padding: 10px;
      margin-left: 10px;
      border-bottom: solid 4px #cecece;
      cursor: pointer;
      text-align: center;
      min-width: 60px;
      border-radius: 4px;
    }
    .channelSelect--active {
      color: var(--pagsmile--color);
      border-color: var(--pagsmile--color);
    }
  }
  .channelSelect__channels {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 800px) {
  .channelSelect {
    .channelSelect__channels {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}
