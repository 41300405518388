.copyButton {
  .copyButton__button {
    font-family: Montserrat-Regular;
    font-size: 15px;
    color: var(--pagsmile--color);
    // background-color: #ffffff;
    // border: 1px solid var(--pagsmile--color);
    text-transform: none;
    border: none;
    border-radius: 4px;
    height: 45px;
    min-width: 150px;
    margin: 5px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
