.orderDetails {
  width: 376px;
  background-color: #f1f4f8;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 20px 30px;
  padding-bottom: 50px;
  font-family: DMSans-Regular;
  font-size: 15px;
  color: #7f8fa4;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .orderDetails__text {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #878787;
  }
  .orderDetails__value {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #0e2c0e;
  }
  .orderDetails__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .orderDetails__ticketImg {
    max-height: 35px;
    max-width: 100px;
    object-fit: scale-down;
  }
  .orderDetails__totalText {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #0e2c0e;
  }
  .corderDetails__totalValue {
    font-family: Montserrat-Regular;
    font-size: 20px;
    margin-left: 6px;
    color: #0e2c0e;
  }
  .orderDetails__return {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    span {
      color: var(--pagsmile--color);
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
